<template>
    <div>
        <b-form-input type="text" :id="id" :list="datalistId" :required="required" :value="value" @input="$emit('input', $event)" @blur="$emit('blur',$event)"
                       :placeholder="placeholder" :state="state" :aria-describedby="ariaDescribedby"  autocomplete="off"
        ></b-form-input>
        <b-form-invalid-feedback :id="ariaDescribedby">
                                {{stateMsg}}</b-form-invalid-feedback>
        <b-form-datalist :id="datalistId" :options="options"></b-form-datalist>
    </div>
</template>
<script>


    export default {
        name: "ap-autocomplete",
        props: {
            id:  {},
            datalistId: {
                type: String,
                required: true,
            },
            required: {
                type: String,
                required: false
            },
            placeholder: {
                type: String,
                required: false
            },
            state: {
                
            },
            ariaDescribedby: {

            },
            stateMsg: {
                
            },
            value: {
                type: String,
                required: false
            },
            text: {
                type: String,
                required: false
            },
            suggestions: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                inputValue: "",
                options: [],
                selectedValue: ''
            }
        },
        watch: {
            value: function () {
                
                // 如果从datalist中选择了值，则不执行向API获取数据的函数
                let keyword = this.value
                if(!this.options.includes(keyword)) {
                    this.$emit('fetch', keyword)
                } else {
                    //设置选中项的value值
                    let filteredArry = this.suggestions.filter(item => {
                        return (keyword == item.text)  
                    });
                    if (filteredArry.length == 1) {
                        this.selectedValue = filteredArry[0].value
                    }
                    
                    this.$emit('select-value', this.selectedValue)
                }
                          
            },
            suggestions: function () {
                let items = this.suggestions
                let newOptions = []
                // suggestions 是value/text对象数组
                // 只把text放到datalist的options参数，以实现数据绑定
                for(let item of items) {
                
                    newOptions.push(item.text)
                }
                this.options = newOptions
            }
        },
        methods: {
            itemChange: function () {
                //暴露给父类传入函数
                this.selectedItem.formFieldIndex = this.formFieldIndex;
                this.$emit("selectedItem", this.selectedItem);
            },
            
            
        },
    }
</script>

<style scoped>

</style>