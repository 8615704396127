

/**
 * 生成value/text对的对象数组，作为，<b-form-datalist>
 * 的options源
 * @param {*} payload 从API获取的源数据
 * @returns 
 */
function dataSuggestions(payload) {
    let items = payload
    let suggestions =[]
    if(items != null && items != undefined) {
        items.forEach(function (item) {
            suggestions.push({value: item, text: item.label+', '+item.countryLabel+'('+ item.type+ ')'})
        })
        return suggestions
    }
        
}

function countrySuggestions(items) {
    let suggestions =[]
    if(items != null && items != undefined) {
        items.forEach(function (item) {
    
            suggestions.push({value: item.code, text: item.name})
        })
        return suggestions
    }    
}


export default {
    dataSuggestions,
    countrySuggestions
}