var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},_vm._l((_vm.$v.travelers.$each.$iter),function(item,index){return _c('div',{key:'pass-traveler-' + index,staticClass:"mb-2 p-3 border-info border-top"},[_c('h5',{staticClass:"font-size-14"},[_vm._v(" Traveler "+_vm._s(parseInt(index) + 1)+"("+_vm._s(_vm.travelers[index].passenger.code)+") "),(
              _vm.travelers[index].passenger.code == 'INFANT' ||
              _vm.travelers[index].passenger.code == 'CHILD' ||
              _vm.travelers[index].passenger.code == 'YOUTH'
            )?_c('span',{staticClass:"text-danger"},[_vm._v(" (Age: "+_vm._s(_vm.travelers[index].age)+") ")]):(
              _vm.travelers[index].fromAge &&
                _vm.travelers[index].toAge &&
                _vm.travelers[index].toAge !== 999
            )?_c('span',{staticClass:"text-danger"},[_vm._v(" (Age range: "+_vm._s(_vm.travelers[index].fromAge + " to " + _vm.travelers[index].toAge)+") ")]):(
              _vm.travelers[index].fromAge && _vm.travelers[index].toAge === 999
            )?_c('span',{staticClass:"text-danger"},[_vm._v(" (Age range: "+_vm._s(_vm.travelers[index].fromAge + " + ")+") ")]):(_vm.travelers[index].toAge)?_c('span',{staticClass:"text-danger"},[_vm._v(" (Age range: "+_vm._s("0 to " + _vm.travelers[index].toAge)+") ")]):_vm._e()]),_c('span',[_vm._v("Your name must appear as on your passport")]),_c('b-row',[(_vm.travelers[index].passenger.title !== undefined)?_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',[_c('b-form-select',{class:{ 'form-group--error': item.passenger.title.$error },attrs:{"options":_vm.titleOptions},model:{value:(item.passenger.title.$model),callback:function ($$v) {_vm.$set(item.passenger.title, "$model", $$v)},expression:"item.passenger.title.$model"}})],1)],1):_vm._e(),(_vm.travelers[index].passenger.firstName !== undefined)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('b-form-input',{class:{
                  'form-group--error': item.passenger.firstName.$error
                },attrs:{"placeholder":"First Name"},model:{value:(item.passenger.firstName.$model),callback:function ($$v) {_vm.$set(item.passenger.firstName, "$model", $$v)},expression:"item.passenger.firstName.$model"}})],1)],1):_vm._e(),(_vm.travelers[index].passenger.lastName !== undefined)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('b-form-input',{class:{ 'form-group--error': item.passenger.lastName.$error },attrs:{"placeholder":"Last Name"},model:{value:(item.passenger.lastName.$model),callback:function ($$v) {_vm.$set(item.passenger.lastName, "$model", $$v)},expression:"item.passenger.lastName.$model"}})],1)],1):_vm._e(),(_vm.travelers[index].passenger.birthDate !== undefined)?_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('b-input-group',{staticClass:"mb-3",class:{
                  'form-group--error': item.passenger.birthDate.$error
                }},[(_vm.travelers[index].passenger.code !== 'ADULT' && _vm.travelers[index].passenger.code !== 'SENIOR')?_c('el-date-picker',{staticClass:"full-width",attrs:{"type":"date","placeholder":"Birth Date - YYYY-MM-DD","picker-options":{
                                  disabledDate: function disabledDate(time) {
                                    return _vm.disableDates(time, _vm.travelers[index]);
                                  }
                              },"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd"},model:{value:(item.passenger.birthDate.$model),callback:function ($$v) {_vm.$set(item.passenger.birthDate, "$model", $$v)},expression:"item.passenger.birthDate.$model"}}):_c('el-date-picker',{staticClass:"full-width",attrs:{"type":"date","placeholder":"Birth Date - YYYY-MM-DD","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","picker-options":{
                                  disabledDate: function disabledDate(time) {
                                    return time.getTime() > new Date().getTime();
                                  }
                              }},model:{value:(item.passenger.birthDate.$model),callback:function ($$v) {_vm.$set(item.passenger.birthDate, "$model", $$v)},expression:"item.passenger.birthDate.$model"}})],1),(item.passenger.birthDate.$error)?_c('span',[_vm._v(_vm._s(_vm.errorMsg.isAgeInvalid.text)),(_vm.travelers[index].toAge !== 999)?_c('span',[_vm._v(" From ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.travelers[index].fromAge)+" "),(_vm.travelers[index].toAge === 999)?_c('span',[_vm._v("+ ")]):_c('span',[_vm._v("to "+_vm._s(_vm.travelers[index].toAge))])]):_vm._e()],1)]):_vm._e()],1),_c('b-row',[(_vm.travelers[index].passenger.countryCode !== undefined)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('VuePhoneNumberInput',{class:{
                  'form-group--error': item.passenger.countryCode.$error
                },attrs:{"preferred-countries":['HK', 'CN'],"default-country-code":"HK","valid-color":"#ced4da","placeholder":"Telephone Number"},on:{"update":function (allValue) {
                    _vm.phoneUpdate(allValue, index);
                  }},model:{value:(item.passenger.countryCode.$model),callback:function ($$v) {_vm.$set(item.passenger.countryCode, "$model", $$v)},expression:"item.passenger.countryCode.$model"}})],1)],1):_vm._e(),(_vm.travelers[index].passenger.email !== undefined)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{class:{ 'form-group--error': item.passenger.email.$error },attrs:{"placeholder":"Email Address"},model:{value:(item.passenger.email.$model),callback:function ($$v) {_vm.$set(item.passenger.email, "$model", $$v)},expression:"item.passenger.email.$model"}})],1)],1):_vm._e()],1),_c('b-row',[(_vm.travelers[index].passenger.addressName !== undefined)?_c('b-col',[_c('b-form-group',[_c('AgencyPortalAutocompleteVue',{class:{ 'form-group--error': item.passenger.address.$error },attrs:{"id":("pass-address-country-" + index),"suggestions":_vm.suggestions,"placeholder":"Passport Country","datalistId":("pass-address-country-datalist-" + index)},on:{"select-value":function (selectedValue) {
                    _vm.selectAddressCountry(selectedValue, index);
                  },"fetch":_vm.getSuggestedCountries,"input":function () { return _vm.inputAddressCountry(index); },"blur":function () { return _vm.blurAddressCountry(index); }},model:{value:(item.passenger.addressName.$model),callback:function ($$v) {_vm.$set(item.passenger.addressName, "$model", $$v)},expression:"item.passenger.addressName.$model"}})],1)],1):_vm._e(),(_vm.travelers[index].passenger.docID !== undefined)?_c('b-col',[_c('b-form-group',[_c('b-form-input',{class:{ 'form-group--error': item.passenger.docID.$error },attrs:{"placeholder":"Passport Number"},model:{value:(item.passenger.docID.$model),callback:function ($$v) {_vm.$set(item.passenger.docID, "$model", $$v)},expression:"item.passenger.docID.$model"}})],1)],1):_vm._e(),(_vm.travelers[index].passenger.expireDate !== undefined)?_c('b-col',[_c('b-form-group',[_c('b-form-datepicker',{class:{
                  'form-group--error': item.passenger.expireDate.$error
                },attrs:{"placeholder":"Expire Date","show-decade-nav":"","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }},model:{value:(item.passenger.expireDate.$model),callback:function ($$v) {_vm.$set(item.passenger.expireDate, "$model", $$v)},expression:"item.passenger.expireDate.$model"}})],1)],1):_vm._e(),(
              _vm.travelers[index].passenger.docHolderNationalityName !== undefined
            )?_c('b-col',[_c('b-form-group',[_c('AgencyPortalAutocompleteVue',{class:{
                  'form-group--error':
                    item.passenger.docHolderNationality.$error
                },attrs:{"id":("pass-passport-country-" + index),"suggestions":_vm.suggestions,"placeholder":"Passport Country","datalistId":("pass-passport-country-datalist-" + index)},on:{"select-value":function (selectedValue) {
                    _vm.selectCountry(selectedValue, index);
                  },"fetch":_vm.getSuggestedCountries,"input":function () { return _vm.inputPassportCountry(index); },"blur":function () { return _vm.blurPassportCountry(index); }},model:{value:(item.passenger.docHolderNationalityName.$model),callback:function ($$v) {_vm.$set(item.passenger.docHolderNationalityName, "$model", $$v)},expression:"item.passenger.docHolderNationalityName.$model"}})],1)],1):_vm._e()],1)],1)}),0),(_vm.isDisplayed)?_c('div',{staticClass:"my-3 d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.backToTrains}},[_vm._v("Back")]),_c('b-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          permission: 'agencyportal.rail.booking',
          effect: 'hidden'
        }),expression:"{\n          permission: 'agencyportal.rail.booking',\n          effect: 'hidden'\n        }"}],attrs:{"variant":"success"},on:{"click":_vm.toPreBook}},[_vm._v("Confirm")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }