<script>
// import RailUtils from '../../utils/RailUtils'
import DateUtils from "../../utils/DateUtils";
import autocompleteApi from "../../api/autocompleteApi";
import railPlacesUtils from "../../utils/autocomplete/railPlacesUtils";
import AgencyPortalAutocompleteVue from "../forms-element/autocomplete/AgencyPortalAutocomplete.vue";
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import dayjs from "dayjs";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "PassTravelerFormVue",
  components: {
    AgencyPortalAutocompleteVue
  },
  props: {
    isDisplayed: {
      type: Boolean,
      default: true
    },
    travelers: {}
  },
  data() {
    return {
      selectedOptions: {
        selectedCountryCode: null,
        selectedTitle: null
      },
      titleOptions: [
        { text: "Title", value: "", disabled: true },
        { text: "Mr", value: "MR" },
        { text: "Ms", value: "MS" }
      ],
      countryCodeOptions: [
        { text: "Country Code", value: null, disabled: true }
      ],
      countryCodeAndNameBridge: "",
      errorMsg: {
        notAllFilled: {
          text: "Please fill out all traveler forms."
        },
        isAgeInvalid: {
          text:
            "The passenger's date of birth does not appear valid for the senior / youth or adult category and Age range: "
        }
      },
      isAllFilled: true,
      isAgeValid: true,
      suggestions: [],
        pickerOptions: {
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }
    };
  },
  computed: {},
  watch: {},
  validations() {
    let passenger = {};
    let leadTraveler = {};
    for (let key in this.travelers[0].passenger) {
      if (key == "email") {
        passenger[key] = { required, email };
      } else if (key == "firstName") {
        passenger[key] = {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        };
      } else if (key == "lastName") {
        passenger[key] = {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        };
      } else if (key == "birthDate") {
        passenger[key] = { required };
      } else if (key == "address") {
        passenger[key] = { required };
      } else {
        passenger[key] = { required };
      }
    }
    for (let key in this.leadTraveler) {
      if (key == "email") {
        leadTraveler[key] = { required, email };
      } else {
        leadTraveler[key] = { required };
      }
    }

    return {
      leadTraveler: leadTraveler,
      travelers: {
        $each: {
          passenger: passenger
        }
      }
    };
  },
  created() {
    console.info(this.travelers);
    // this.travelers = RailUtils.getTravelersFormArray(this.travelerQuantity, 'Pass')
  },
  methods: {
    phoneUpdate(allValue, index) {
      this.travelers[index].passenger.telephoneNumber =
        allValue.formattedNumber;
    },
    validateFieldState(index) {
      if (
        this.travelers[index].passenger.firstName.length < 2 &&
        this.travelers[index].passenger.firstName.length != ""
      ) {
        return false;
      }
    },
    validateBirth(index, birth, fromAge, toAge) {
      let isValid = dayjs(birth, "YYYY-MM-DD", true).isValid();
      let age = DateUtils.getDuration("", birth, "year");
      if (!isValid || age < fromAge || age > toAge) {
        this.$v.travelers.$each[index].$model.passenger.birthDate = "";
        return false;
      } else {
        let today = new Date(DateUtils.format(new Date()));
        let date = new Date(birth);
        if (date > today) {
          this.$v.travelers.$each[index].$model.passenger.birthDate = "";
          return false;
        } else {
          this.$v.travelers.$each[index].$model.passenger.birthDate = birth;
          return true;
        }
      }
    },
    dateDisabled(ymd, date) {
      let number = Date.now();
      return date > number;
    },
    backToTrains: function() {
      this.$router.push({
        name: "passOptions"
      });
    },
    getSuggestedCountries(str) {
      autocompleteApi
        .getCountries(str)
        .then(res => {
          let items = res.data;
          let destinations = railPlacesUtils.countrySuggestions(items);
          this.suggestions = destinations;
        })
        .catch(() => {
          this.suggestions = [{ value: "", text: "empty suggestion" }];
        });
    },
    selectCountry(countryCode, index) {
      console.info("select");
      this.travelers[index].passenger.docHolderNationality = countryCode;
    },
    selectAddressCountry(countryCode, index) {
      this.travelers[index].passenger.address = countryCode;
    },
    inputAddressCountry(index) {
      console.info("input");
      this.travelers[index].passenger.address = "";
    },
    blurAddressCountry(index) {
      this.$v.travelers.$each[index].passenger.address.$touch();
    },
    inputPassportCountry(index) {
      this.travelers[index].passenger.docHolderNationality = "";
    },
    blurPassportCountry(index) {
      this.$v.travelers.$each[
        index
      ].$model.passenger.docHolderNationality.$touch();
    },
    toPreBook: function() {
      this.$v.$touch();
      //
      // this.checkPassengerInfos()
      // if(this.isAllFilled && this.isAgeValid){
      //
      //     this.$emit('confirm', this.travelers)
      // }
      if (!this.$v.$error) {
        this.$emit("confirm", this.travelers);
      }
    },
    checkPassengerInfos() {
      this.isAllFilled = true;
      for (let i = 0; i < this.travelers.length; i++) {
        let passenger = this.travelers[i].passenger;
        for (let j in passenger) {
          if (passenger[j] == "") {
            this.isAllFilled = false;
            break;
          }
        }
        if (!this.isAllFilled) break;
      }
    },
      disableDates(time, passenger){
          let today = DateUtils.format(new Date())
          let maxDate = dayjs(today).subtract(passenger.age, "year");
          let minDate = dayjs(today).subtract(passenger.age+1, "year").add(1, "day");
          let compareDate = dayjs(new Date(time.getTime()));
          let isBefore = compareDate.isBefore(minDate);
          let isAfter = compareDate.isAfter(maxDate);
          let b = isBefore || isAfter;
          return b;
      }
  }
};
</script>

<template>
  <div>
    <b-card no-body>
      <div
        v-for="(item, index) in $v.travelers.$each.$iter"
        :key="'pass-traveler-' + index"
        class="mb-2 p-3 border-info border-top"
      >
        <h5 class="font-size-14">
          Traveler {{ parseInt(index) + 1 }}({{
            travelers[index].passenger.code
          }})
            <span
                    class="text-danger"
                    v-if="
              travelers[index].passenger.code == 'INFANT' ||
              travelers[index].passenger.code == 'CHILD' ||
              travelers[index].passenger.code == 'YOUTH'
            "
            >
            (Age:
            {{ travelers[index].age }})
          </span>
            <span
                    class="text-danger"
                    v-else-if="
              travelers[index].fromAge &&
                travelers[index].toAge &&
                travelers[index].toAge !== 999
            "
            >
            (Age range:
            {{ travelers[index].fromAge + " to " + travelers[index].toAge }})
          </span>
            <span
                    class="text-danger"
                    v-else-if="
              travelers[index].fromAge && travelers[index].toAge === 999
            "
            >
            (Age range: {{ travelers[index].fromAge + " + " }})
          </span>
            <span class="text-danger" v-else-if="travelers[index].toAge">
            (Age range: {{ "0 to " + travelers[index].toAge }})
          </span>
        </h5>
        <span>Your name must appear as on your passport</span>
        <b-row>
          <b-col cols="2" v-if="travelers[index].passenger.title !== undefined">
            <b-form-group>
              <b-form-select
                v-model="item.passenger.title.$model"
                :options="titleOptions"
                :class="{ 'form-group--error': item.passenger.title.$error }"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
            v-if="travelers[index].passenger.firstName !== undefined"
          >
            <b-form-group>
              <b-form-input
                v-model="item.passenger.firstName.$model"
                placeholder="First Name"
                :class="{
                  'form-group--error': item.passenger.firstName.$error
                }"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
            v-if="travelers[index].passenger.lastName !== undefined"
          >
            <b-form-group>
              <b-form-input
                v-model="item.passenger.lastName.$model"
                placeholder="Last Name"
                :class="{ 'form-group--error': item.passenger.lastName.$error }"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
           <!--生日-->
          <b-col
            cols="4"
            v-if="travelers[index].passenger.birthDate !== undefined"
          >
            <!--                <b-form-group>-->
            <!--                    <b-form-datepicker placeholder="Date of Birth"-->
            <!--                    :id="`pass-birth-date-${index}`"-->
            <!--                    v-model="item.passenger.birthDate"-->
            <!--                    show-decade-nav-->
            <!--                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
            <!--                    :state="validateAge(index)"-->
            <!--                    :aria-describedby="`birth-date-feedback-${index}`"-->
            <!--                    ></b-form-datepicker>-->
            <!--                    <b-form-invalid-feedback-->
            <!--                    :id="`pass-birth-date-feedback-${index}`"-->
            <!--                    >{{errorMsg.isAgeInvalid.text}}-->
            <!--                    </b-form-invalid-feedback>-->
            <!--                </b-form-group>-->
            <div>
              <b-input-group
                class="mb-3"
                :class="{
                  'form-group--error': item.passenger.birthDate.$error
                }"
              >
<!--                <el-date-picker-->
<!--                  v-model="item.passenger.birthDate.$model"-->
<!--                  type="date"-->
<!--                  class="full-width"-->
<!--                  placeholder="Birth Date - YYYY-MM-DD"-->
<!--                  :picker-options="pickerOptions"-->
<!--                  format="yyyy-MM-dd"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  @blur="-->
<!--                    validateBirth(-->
<!--                      index,-->
<!--                      item.passenger.birthDate.$model,-->
<!--                      travelers[index].fromAge,-->
<!--                      travelers[index].toAge-->
<!--                    )-->
<!--                  "-->
<!--                >-->
<!--                </el-date-picker>-->
                  <el-date-picker
                          v-if="travelers[index].passenger.code !== 'ADULT' && travelers[index].passenger.code !== 'SENIOR'"
                          v-model="item.passenger.birthDate.$model"
                          type="date"
                          class="full-width"
                          placeholder="Birth Date - YYYY-MM-DD"
                          :picker-options="{
                                  disabledDate(time) {
                                    return disableDates(time, travelers[index]);
                                  }
                              }"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                  />
                  <el-date-picker
                          v-else
                          v-model="item.passenger.birthDate.$model"
                          type="date"
                          class="full-width"
                          placeholder="Birth Date - YYYY-MM-DD"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          :picker-options="{
                                  disabledDate(time) {
                                    return time.getTime() > new Date().getTime();
                                  }
                              }"
                  />
              </b-input-group>
              <span v-if="item.passenger.birthDate.$error"
                >{{ errorMsg.isAgeInvalid.text
                }}<span v-if="travelers[index].toAge !== 999"> From </span>
                {{ travelers[index].fromAge }}
                <span v-if="travelers[index].toAge === 999">+ </span
                ><span v-else>to {{ travelers[index].toAge }}</span>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            v-if="travelers[index].passenger.countryCode !== undefined"
          >
            <b-form-group>
              <VuePhoneNumberInput
                v-model="item.passenger.countryCode.$model"
                @update="
                  allValue => {
                    phoneUpdate(allValue, index);
                  }
                "
                :class="{
                  'form-group--error': item.passenger.countryCode.$error
                }"
                :preferred-countries="['HK', 'CN']"
                default-country-code="HK"
                valid-color="#ced4da"
                placeholder="Telephone Number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="travelers[index].passenger.email !== undefined">
            <b-form-group>
              <b-form-input
                v-model="item.passenger.email.$model"
                :class="{ 'form-group--error': item.passenger.email.$error }"
                placeholder="Email Address"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="travelers[index].passenger.addressName !== undefined">
            <b-form-group>
              <AgencyPortalAutocompleteVue
                :id="`pass-address-country-${index}`"
                v-model="item.passenger.addressName.$model"
                :class="{ 'form-group--error': item.passenger.address.$error }"
                @select-value="
                  selectedValue => {
                    selectAddressCountry(selectedValue, index);
                  }
                "
                @fetch="getSuggestedCountries"
                @input="() => inputAddressCountry(index)"
                @blur="() => blurAddressCountry(index)"
                :suggestions="suggestions"
                placeholder="Passport Country"
                :datalistId="`pass-address-country-datalist-${index}`"
              ></AgencyPortalAutocompleteVue>
            </b-form-group>
          </b-col>
          <b-col v-if="travelers[index].passenger.docID !== undefined">
            <b-form-group>
              <b-form-input
                v-model="item.passenger.docID.$model"
                placeholder="Passport Number"
                :class="{ 'form-group--error': item.passenger.docID.$error }"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="travelers[index].passenger.expireDate !== undefined">
            <b-form-group>
              <b-form-datepicker
                placeholder="Expire Date"
                show-decade-nav
                v-model="item.passenger.expireDate.$model"
                :class="{
                  'form-group--error': item.passenger.expireDate.$error
                }"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col
            v-if="
              travelers[index].passenger.docHolderNationalityName !== undefined
            "
          >
            <b-form-group>
              <AgencyPortalAutocompleteVue
                :id="`pass-passport-country-${index}`"
                v-model="item.passenger.docHolderNationalityName.$model"
                @select-value="
                  selectedValue => {
                    selectCountry(selectedValue, index);
                  }
                "
                @fetch="getSuggestedCountries"
                :suggestions="suggestions"
                :class="{
                  'form-group--error':
                    item.passenger.docHolderNationality.$error
                }"
                @input="() => inputPassportCountry(index)"
                @blur="() => blurPassportCountry(index)"
                placeholder="Passport Country"
                :datalistId="`pass-passport-country-datalist-${index}`"
              ></AgencyPortalAutocompleteVue>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!--    <b-form-invalid-feedback-->
      <!--    :state="isAllFilled"-->
      <!--    >-->
      <!--        {{errorMsg.notAllFilled.text}}-->
      <!--    </b-form-invalid-feedback>-->
    </b-card>

    <div class="my-3 d-flex justify-content-between" v-if="isDisplayed">
      <b-button variant="info" @click="backToTrains">Back</b-button>

      <b-button
        variant="success"
        @click="toPreBook"
        v-permission="{
          permission: 'agencyportal.rail.booking',
          effect: 'hidden'
        }"
        >Confirm</b-button
      >
    </div>
  </div>
</template>

<style scoped></style>
